import dynamic from "next/dynamic"

export const blockMapping = {
  /**
   * ACF
   */
  "acf/checkout-form": dynamic(() =>
    import("@/blocks/acf/checkoutForm/checkoutForm")
  ),
  "acf/checkout-form-light": dynamic(() =>
    import("@/blocks/acf/checkout-form-light/checkout-form-light")
  ),
  "acf/gift-archive": dynamic(() =>
    import("@/blocks/acf/gift-archive/gift-archive")
  ),
  "acf/wufoo-form": dynamic(() => import("@/blocks/acf/wufooForm/wufooForm")),
  "acf/swish": dynamic(() => import("@/blocks/acf/swish/swish")),
  "acf/faq": dynamic(() => import("@/blocks/acf/faq/faq")),
  "acf/cookie-declaration": dynamic(() =>
    import("@/blocks/acf/cookieDeclaration/cookieDeclaration")
  ),
  "acf/active-campaign": dynamic(() =>
    import("@/blocks/acf/active-campaign/active-campaign")
  ),

  /**
   * Core
   */
  "core/button": dynamic(() => import("@/blocks/core/button/button")),
  "core/buttons": dynamic(() => import("@/blocks/core/buttons/buttons")),
  "core/quote": dynamic(() => import("@/blocks/core/quote/quote")),
  "core/group": dynamic(() => import("@/blocks/core/group/group")),
  "core/heading": dynamic(() => import("@/blocks/core/heading/heading")),
  "core/image": dynamic(() => import("@/blocks/core/image/image")),
  "core/list": dynamic(() => import("@/blocks/core/list/list")),
  "core/paragraph": dynamic(() => import("@/blocks/core/paragraph/paragraph")),
  "core/table": dynamic(() => import("@/blocks/core/table/table")),

  /**
   * Core Custom
   */
  "core/login-form": dynamic(() =>
    import("@/blocks/core/login-form/login-form")
  ),
  "core/password-form": dynamic(() =>
    import("@/blocks/core/password-form/password-form")
  ),
  /**
   * Theme
   */
  "theme/accordion": dynamic(() =>
    import("@/blocks/theme/accordion/accordion")
  ),
  "theme/archive-articles": dynamic(() =>
    import("@/blocks/theme/archive-articles/archive-articles")
  ),
  "theme/archive-header": dynamic(() =>
    import("@/blocks/theme/archive-header/archive-header")
  ),
  "theme/content-block": dynamic(() =>
    import("@/blocks/theme/content-block/content-block")
  ),
  "theme/content-blocks": dynamic(() =>
    import("@/blocks/theme/content-blocks/content-blocks")
  ),
  "theme/featured-article": dynamic(() =>
    import("@/blocks/theme/featured-article/featured-article")
  ),
  "theme/hero": dynamic(() => import("@/blocks/theme/hero/hero")),
  "theme/blurbs": dynamic(() => import("@/blocks/theme/blurbs/blurbs")),
  "theme/blurb": dynamic(() => import("@/blocks/theme/blurbs/blurb")),
  "theme/article-teasers": dynamic(() =>
    import("@/blocks/theme/article-teasers/article-teasers")
  ),
  "theme/categories": dynamic(() =>
    import("@/blocks/theme/categories/categories")
  ),
  "theme/link-list": dynamic(() =>
    import("@/blocks/theme/link-list/link-list")
  ),
  "theme/link": dynamic(() => import("@/blocks/theme/link/link")),
  "theme/links": dynamic(() => import("@/blocks/theme/links/links")),
  "theme/latest-articles": dynamic(() =>
    import("@/blocks/theme/latest-articles/latest-articles")
  ),
  "theme/icon-card": dynamic(() =>
    import("@/blocks/theme/icon-card/icon-card")
  ),
  "theme/icon-cards": dynamic(() =>
    import("@/blocks/theme/icon-cards/icon-cards")
  ),
  "theme/image-slider": dynamic(() =>
    import("@/blocks/theme/image-slider/image-slider")
  ),
  "theme/youtube": dynamic(() => import("@/blocks/theme/youtube/youtube")),
  "theme/video-teasers": dynamic(() =>
    import("@/blocks/theme/video-teasers/video-teasers")
  ),
  "theme/pdf-list": dynamic(() => import("@/blocks/theme/pdf-list/pdf-list")),
  "theme/post-slider": dynamic(() =>
    import("@/blocks/theme/post-slider/post-slider")
  ),
  //"theme/memory-sheet": dynamic(() => import("@/blocks/theme/memory-sheet")),
  "theme/personnel-header": dynamic(() =>
    import("@/blocks/theme/personnel-header/personnel-header")
  ),
  "theme/archive-personnel": dynamic(() =>
    import("@/blocks/theme/archive-personnel/archive-personnel")
  ),
  "theme/calendar-header": dynamic(() =>
    import("@/blocks/theme/calendar-header/calendar-header")
  ),
  "theme/archive-calendar": dynamic(() =>
    import("@/blocks/theme/archive-calendar/archive-calendar")
  ),
  "theme/useragent-conditional-content": dynamic(() =>
    import(
      "@/blocks/theme/useragent-conditional-content/useragent-conditional-content"
    )
  ),
  "theme/lightbox": dynamic(() => import("@/blocks/theme/lightbox/lightbox")),
  "theme/current-campaigns": dynamic(() =>
    import("@/blocks/theme/current-campaigns/current-campaigns")
  ),

  // CalendarSingle
  "theme/calendar-single": dynamic(() =>
    import("@/blocks/theme/calendar-single/calendar-single")
  ),
  "theme/event-main": dynamic(() =>
    import("@/blocks/theme/calendar-single/event-main")
  ),
  "theme/event-content": dynamic(() =>
    import("@/blocks/theme/calendar-single/event-content")
  ),
  "theme/calendar-cancel-form": dynamic(() =>
    import("@/blocks/theme/calendar-cancel-form/calendar-cancel-form")
  ),
  "theme/event-info": dynamic(() =>
    import("@/blocks/theme/calendar-single/event-info")
  ),
  "theme/event-form": dynamic(() =>
    import("@/blocks/theme/calendar-single/event-form")
  ),
  "theme/related-events": dynamic(() =>
    import("@/blocks/theme/calendar-single/related-events")
  ),
  "theme/event-meta": dynamic(() =>
    import("@/blocks/theme/calendar-single/event-meta")
  ),

  "theme/press-via-tt": dynamic(() =>
    import("@/blocks/theme/press-via-tt/press-via-tt")
  ),
  "theme/personnel": dynamic(() =>
    import("@/blocks/theme/personnel/personnel")
  ),
  "theme/google-maps": dynamic(() =>
    import("@/blocks/theme/google-maps/google-maps")
  ),
  "theme/spotify": dynamic(() => import("@/blocks/theme/spotify/spotify")),
  "theme/gravity-form": dynamic(() =>
    import("@/blocks/theme/gravity-form/gravity-form")
  ),
  "theme/gravity-form-result": dynamic(() =>
    import("@/blocks/theme/gravity-form-result/gravity-form-result")
  ),
  "theme/text-block": dynamic(() =>
    import("@/blocks/theme/text-block/text-block")
  ),
  "theme/text-block-heading": dynamic(() =>
    import("@/blocks/theme/text-block/text-block-heading")
  ),
  "theme/text-block-body": dynamic(() =>
    import("@/blocks/theme/text-block/text-block-body")
  ),
  "theme/multisoft-snippet": dynamic(() =>
    import("@/blocks/theme/multisoft-snippet/multisoft-snippet")
  ),
  "theme/content-with-form": dynamic(() =>
    import("@/blocks/theme/content-with-form/content-with-form")
  ),
  "theme/form-content": dynamic(() =>
    import("@/blocks/theme/form-content/form-content")
  ),
  "theme/sj-widget": dynamic(() =>
    import("@/blocks/theme/sj-widget/sj-widget")
  ),
  "theme/countdown-timer": dynamic(() =>
    import("@/blocks/theme/countdown-timer/countdown-timer")
  ),
  "theme/sound-clip": dynamic(() =>
    import("@/blocks/theme/sound-clip/sound-clip")
  ),
  "theme/lawly-widget": dynamic(() =>
    import("@/blocks/theme/lawly-widget/lawly-widget")
  ),
  "theme/iframe": dynamic(() => import("@/blocks/theme/iframe/iframe")),
  /**
   * Flowerfields
   */
  "theme/flowerfield-map": dynamic(() =>
    import("@/blocks/theme/flowerfields/flowerfieldMap")
  ),
  "theme/flowerfield-register": dynamic(() =>
    import("@/blocks/theme/flowerfields/flowerfieldRegister")
  ),
  "theme/flowerfield-stats": dynamic(() =>
    import("@/blocks/theme/flowerfields/flowerfieldStats")
  ),
  "theme/flowerfield-single": dynamic(() =>
    import("@/blocks/theme/flowerfields/flowerfieldSingle")
  ),
  /**
   * Article
   */
  "theme/article-hero": dynamic(() =>
    import("@/blocks/theme/article-hero/article-hero")
  ),
  "theme/article-tag": dynamic(() =>
    import("@/blocks/theme/article-hero/article-tag")
  ),
  "theme/article-terms": dynamic(() =>
    import("@/blocks/theme/article-hero/article-terms")
  ),
  "theme/article-meta": dynamic(() =>
    import("@/blocks/theme/article-hero/article-meta")
  ),
  "theme/article-sidebars": dynamic(() =>
    import("@/blocks/theme/article-sidebars/article-sidebars")
  ),
  "theme/article-cta": dynamic(() =>
    import("@/blocks/theme/article-cta/article-cta")
  ),

  // Search page
  "theme/search-header": dynamic(() =>
    import("@/blocks/theme/search-header/search-header")
  ),
  "theme/search-results": dynamic(() =>
    import("@/blocks/theme/search-results/search-results")
  ),

  //Campaign
  "theme/campaign-footer": dynamic(() =>
    import("@/blocks/theme/campaign-footer")
  ),
  "theme/name-collection": dynamic(() =>
    import("@/blocks/theme/name-collection/name-collection")
  ),
  "theme/collection-info": dynamic(() =>
    import("@/blocks/theme/name-collection/collection-info")
  ),
  "theme/progress-bar": dynamic(() =>
    import("@/blocks/theme/name-collection/progress-bar")
  ),
  "theme/eco-effect": dynamic(() =>
    import("@/blocks/theme/eco-effect/eco-effect")
  ),
  "theme/wild-neighbors-activities": dynamic(() =>
    import("@/blocks/theme/wild-neighbors-activities/wild-neighbors-activities")
  ),
  "theme/wild-neighbors-activity": dynamic(() =>
    import("@/blocks/theme/wild-neighbors-activity/wild-neighbors-activity")
  ),
  "theme/wild-neighbors-map": dynamic(() =>
    import("@/blocks/theme/wild-neighbors-map/wild-neighbors-map")
  ),
  "theme/wild-neighbors-register": dynamic(() =>
    import("@/blocks/theme/wild-neighbors-register/wild-neighbors-register")
  ),
  "theme/wild-neighbors-single": dynamic(() =>
    import("@/blocks/theme/wild-neighbors-single/wild-neighbors-single")
  ),
  "theme/report-chart": dynamic(() =>
    import("@/blocks/theme/report-chart/report-chart")
  ),
  "theme/report-heading": dynamic(() =>
    import("@/blocks/theme/report-heading/report-heading")
  ),
  "theme/giant-text": dynamic(() =>
    import("@/blocks/theme/giant-text/giant-text")
  ),
  "theme/number-focus": dynamic(() =>
    import("@/blocks/theme/number-focus/number-focus")
  ),
  "theme/number-focus-number": dynamic(() =>
    import("@/blocks/theme/number-focus/number-focus-number")
  ),
  "theme/hero-campaign": dynamic(() =>
    import("@/blocks/theme/hero-campaign/hero-campaign")
  ),
  "theme/animated-svg": dynamic(() =>
    import("@/blocks/theme/animated-svg/animated-svg")
  ),
  "theme/flowerfield-numbers": dynamic(() =>
    import("@/blocks/theme/flowerfields/flowerfieldNumbers")
  ),
  "theme/bubbles": dynamic(() => import("@/blocks/theme/bubbles/bubbles")),
  "theme/annual-wheel": dynamic(() =>
    import("@/blocks/theme/annual-wheel/annual-wheel")
  ),

  "theme/scrolly": dynamic(() => import("@/blocks/theme/scrolly/scrolly")),

  // GiftShop
  "acf/product-archive": dynamic(() =>
    import("@/blocks/acf/product-archive/product-archive")
  ),
  "acf/product-single": dynamic(() =>
    import("@/blocks/acf/product-single/product-single")
  ),
  "acf/related-products": dynamic(() =>
    import("@/blocks/acf/related-products/related-products")
  ),
  "theme/order-summary": dynamic(() =>
    import("@/blocks/theme/order-summary/order-summary")
  ),

  // Member
  "member/login": dynamic(() => import("@/blocks/member/login/login")),
  "member/welcome": dynamic(() => import("@/blocks/member/welcome/welcome")),
  "member/archive-articles": dynamic(() =>
    import("@/blocks/member/archive-articles/archive-articles")
  ),
  "member/archive-events": dynamic(() =>
    import("@/blocks/member/archive-events/archive-events")
  ),
  "member/article-cards": dynamic(() =>
    import("@/blocks/member/article-cards/article-cards")
  ),
  "member/personal-feed": dynamic(() =>
    import("@/blocks/member/personal-feed/personal-feed")
  ),
  "member/account-details": dynamic(() =>
    import("@/blocks/member/account-details/account-details")
  ),
  "member/membership": dynamic(() =>
    import("@/blocks/member/membership/membership")
  ),
  "member/stromberg-catalog": dynamic(() =>
    import("@/blocks/member/stromberg-catalog/stromberg-catalog")
  )
}
