import React, { useState, useEffect } from "react"
import { useMediaPredicate } from "react-media-hook"
import Button from "@/common/button/button"
import Magnifier from "@/common/icons/magnifier"
import { Caret } from "@/common/icons/caret"
import CheckmarkRounded from "@/common/icons/checkmark-rounded"
import Funnel from "@/common/icons/funnel"
import Remove from "@/common/icons/remove"
import { useArchiveContext } from "@/lib/contexts/archiveContext"
import { searchContent } from "@/lib/api/search"
import { useAppContext } from "@/lib/contexts/appContext"
import { trackSearch } from "@/lib/helpers/tagManager"
import { useRouter } from "next/router"
import usePrevious from "@/lib/hooks/usePrevious"
import styles from "./search-form.module.css"
import classNames from "classnames"

import Map from "@/common/icons/map"

export default function SearchForm({
  data,
  autoload = true,
  handleShowMap = null, //Only used with CalendarHeader
  mapActive = true, //Only used with CalendarHeader
  handleAdvBtn = null,
  expand = false,
  children
}) {
  const isMobile = useMediaPredicate("(max-width: 767px)")
  const appContextStore = useAppContext()
  const router = useRouter()
  const store = useArchiveContext()
  const prevPage = usePrevious(store.page)

  const [advOpen, setAdvanced] = useState(expand)
  const [filterObj, setFilterObj] = useState({})
  const [currentQuery, setCurrentQuery] = useState([])
  const [inputValue, setInputValue] = useState("")

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(searchParams)

    if (store.loading) {
      return
    }

    if (store.firstRequest && Object.keys(params).length === 0) {
      return
    }

    if (JSON.stringify(params) === JSON.stringify(currentQuery)) {
      return
    }

    const phrase = searchParams.get("s") ?? ""
    const sortBy = searchParams.get("sort") ?? ""
    const page = searchParams.get("page") ?? 1
    setInputValue(phrase)

    const selected = []

    switch (data.context) {
      case "archive":
        break

      case "site":
      case "category":
      case "activity":
        data.options.forEach((item) => {
          if (item.slug !== searchParams.get("content")) {
            return
          }
          selected.push({
            slug: item.slug,
            label: item.label,
            taxonomy: "content"
          })
        })
        break

      case "collab":
        data.filters.forEach((item) => {
          const key = item?.taxonomy

          if (!key) {
            return
          }

          let value = searchParams.get(key.replace("-", "_"))
          value = item?.method === "filter" ? parseInt(value, 10) : value

          const select = item.options
            .filter((item) => item.slug === value)
            .shift()

          if (select) {
            selected.push({
              slug: select.slug + "",
              label: select.label,
              taxonomy: item.taxonomy
            })
          }
        })
        break

      case "calendar":
      case "school":
        if (data.type === "generic") {
          break
        }
        data.filters.forEach((item) => {
          const key = item?.taxonomy

          if (!key) {
            return
          }

          const values = searchParams.get(key.replace("-", "_"))

          if (!values) {
            return
          }

          values.split(" ").forEach((value) => {
            const select = item.options
              .filter((item) => item.slug === value)
              .shift()

            if (select) {
              selected.push({
                slug: select.slug,
                label: select.label,
                taxonomy: item.taxonomy
              })
            }
          })
        })
        break
    }

    store.setFullQuery(phrase, selected, sortBy, page)
    setAdvanced(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  useEffect(() => {
    if (store.firstRequest) {
      store.setFirstRequest(false)
      return
    }

    if (store.context === "site" && store.phrase.length === 0) {
      return
    }

    doSearch(!data.paginate && store.page !== prevPage)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    store.selected,
    store.phrase,
    store.sortBy,
    store.page,
    appContextStore.pathData.path
  ])

  const pushQuery = (url, phrase = null, shallow = true) => {
    let query = {}
    store.selected.forEach((item) => {
      const taxonomy = item.taxonomy.replace("-", "_")
      if (taxonomy in query) {
        query[taxonomy] = query[taxonomy] + " " + item.slug
        return
      }

      if (item.slug.length > 0) {
        query[taxonomy] = item.slug
      }
    })

    if (store.sortBy.length > 0) {
      query.sort = store.sortBy
    }

    if (phrase === null && store.phrase.length > 0) {
      query.s = store.phrase
    }

    if (phrase && phrase.length > 0) {
      query.s = phrase
    }

    if (store.page > 1) {
      query.page = store.page + ""
    }

    setCurrentQuery(query)

    router.push(
      {
        pathname: url,
        query: query
      },
      null,
      { shallow: shallow }
    )
  }

  const handleConfirm = (e) => {
    e.preventDefault()
    store.setPhrase(appContextStore.searchFormRef.current.value)
    store.setPage(1)

    if (autoload) {
      return
    }

    pushQuery(data.url, appContextStore.searchFormRef.current.value, false)
  }

  const clearTaxonomy = (taxonomy) => {
    let current = [...store.selected]
    current = current.filter((item) => item.taxonomy !== taxonomy)
    store.setSelected(current)
    store.setOpenFilters([])
    store.setPage(1)
  }

  const clearTerm = (term) => {
    let current = [...store.selected]
    current = current.filter((item) => item.slug !== term)
    store.setSelected(current)
    store.setPage(1)
  }

  const clearAll = () => {
    store.setPhrase("")
    store.setSelected([])
    store.setOpenFilters([])
    store.setPage(1)
    setInputValue("")
  }

  const doSelect = (e) => {
    store.setPage(1)
    let item = data.options
      .filter((item) => item.slug === e.target.value)
      .shift()
    if (item !== undefined) {
      let taxonomy = data.taxonomy ?? "content"
      store.setSelected([
        { slug: e.target.value, label: item.label, taxonomy: taxonomy }
      ])
    }
  }

  const doMultiSelect = (e, item) => {
    store.setPage(1)

    if (item.method === "sort") {
      store.setSortBy(e.target.value)
      return
    }

    if (item.reset) {
      store.setSelected([
        { slug: e.target.value, label: item.label, taxonomy: item.taxonomy }
      ])
      return
    }

    let current = [...store.selected].filter(
      (select) => select.taxonomy !== item.taxonomy
    )

    current.push({
      slug: e.target.value,
      label: item.label,
      taxonomy: item.taxonomy
    })

    store.setSelected(current)
  }

  const getOptionsFromParent = (item) => {
    const parent =
      store.selected.filter((select) => select.taxonomy === item.parent).shift()
        ?.slug ?? 0

    return item.options.filter((option) => {
      return option.parents.includes(parent)
    })
  }

  const handleSelected = (option) => {
    let current = [...store.selected]
    if (current.indexOf(option) === -1) {
      current.push(option)
    } else {
      current = current.filter((value) => option !== value)
    }
    store.setPage(1)
    store.setSelected(current)
  }

  const getFilters = () => {
    const term = store.object.terms?.filter((term) => term.isCurrent).shift()
    let filters = {
      postTypes: data.postTypes ?? [],
      taxonomy:
        term === undefined
          ? []
          : [{ terms: term.slug, taxonomy: store.object.taxonomy }],
      content: []
    }

    const taxonomies = []

    if (data.filters !== undefined) {
      data.filters
        .filter((item) => item.taxonomy !== "content")
        .forEach((filter) => {
          const slugs = store.selected
            .filter((item) => item.taxonomy === filter.taxonomy)
            .map((item) => {
              return item.slug
            })
          if (slugs.length > 0) {
            taxonomies.push({ taxonomy: filter.taxonomy, terms: slugs })
          }
        })

      if (taxonomies.length > 0) {
        filters.taxonomy = taxonomies
      }
    }

    let content = store.selected
      .filter((item) => item.taxonomy === "content" && item.slug !== "")
      .map((item) => {
        return item.slug
      })

    if (content.length > 0) {
      filters.content = content
    }

    if (data.taxonomy !== undefined) {
      filters.taxonomy = data.taxonomy
    }

    switch (data.context) {
      case "site":
        filters.section = false
        // byta phrase i url om den finns
        // router.push("/sok/")
        break

      default:
        break
    }

    filters.page = parseInt(store.page, 10)
    filters.count = data.count
    filters.sortBy = store.sortBy

    setFilterObj(filters)

    console.log({ filters })
    return filters
  }

  const doSearch = async (addToList = false) => {
    if (!autoload) {
      return
    }

    if (data.length === 0) {
      return
    }

    if (!addToList) {
      store.setList([], null, 0, 0, [], null)
    }

    store.setFirstRequest(false)
    store.setProgress(true)

    let filters = getFilters()

    await searchContent(store.phrase, data.context, filters, "grid").then(
      (response) => {
        if (response) {
          if (addToList) {
            store.setList(
              [...store.list, ...response.result],
              response.cardType,
              response.totalCount,
              response.maxPage,
              response.hash
            )
          } else {
            store.setList(
              response.result,
              response.cardType,
              response.totalCount,
              response.maxPage,
              response.hash
            )
          }
        }

        pushQuery(window.location.pathname)

        trackSearch(store.phrase, data.context)
        store.setProgress(false)
      }
    )
  }

  const handleOpenFilters = (slug) => {
    let newData = []
    if (store.openFilters.indexOf(slug) === -1) {
      newData.push(slug)
    }

    store.setOpenFilters(newData)
  }

  const AdvButton = () => {
    if (!data.type || data.type === "generic") {
      return null
    }

    if (data.type === "select") {
      let options = data.options.map((item, key) => {
        return (
          <option key={key} value={item.slug}>
            {item.label}
          </option>
        )
      })

      let value = store.selected.length > 0 ? store.selected[0].slug : ""

      if (filterObj?.content !== undefined) {
        value = filterObj.content[0]
      }

      return (
        <div className="adv-select">
          <label htmlFor="type-select">{data.label}</label>
          <select
            value={value}
            onChange={(e) => {
              doSelect(e)
            }}
            className={styles.select}>
            {options}
          </select>
          <Caret className="caret" />
        </div>
      )
    }

    return (
      <Button
        theme={advOpen ? "light-lime" : "secondary"}
        className="adv-button"
        onClick={() => {
          setAdvanced(!advOpen)
          handleAdvBtn && handleAdvBtn(!advOpen)
          if (!advOpen) {
            store.setOpenFilters([])
          }
        }}>
        <Funnel className="block w-4 md:hidden" />
        <span className="hidden md:block">{data.strings.filters}</span>
      </Button>
    )
  }

  const Checkboxes = ({ item }) => {
    const isOpen = store.openFilters.indexOf(item.taxonomy) > -1
    const checkCount = store.selected.filter(
      (select) => select.taxonomy === item.taxonomy
    ).length

    const options = item.options.map((option, key) => {
      option.taxonomy = item.taxonomy

      return <Checkbox key={key} option={option} />
    })

    const word = checkCount === 1 ? item.strings[0] : item.strings[1]
    const currentChoice =
      checkCount === 0
        ? data.strings.all
        : checkCount +
          " " +
          word +
          " " +
          (checkCount === 1 ? item.singular : item.plural).toLowerCase()

    return (
      <div
        className={classNames([
          styles.checkboxWrapper,
          {
            "is-open": isOpen
          }
        ])}>
        <div
          className={classNames({
            checkboxes: true,
            "has-choices": checkCount > 0,
            "has-shadow": isOpen
          })}>
          <header onClick={() => handleOpenFilters(item.taxonomy)}>
            <div className="current-choice">
              <span>{item.singular}</span>
              {currentChoice}
            </div>
            <div className="clear-filter">
              {checkCount > 0 && (
                <div
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOpenFilters(item.taxonomy)
                    clearTaxonomy(item.taxonomy)
                  }}
                  className="square">
                  <Remove className="w-2" strokeColor="#101010" />
                </div>
              )}
            </div>
            <Caret className="caret" />
          </header>
          {isOpen && (
            <div className="open-filter">
              <div className="overlay"></div>
              <ul>{options}</ul>
              {!autoload && (
                <div className="button-wrapper">
                  <Button
                    font="sans"
                    size="small"
                    theme={checkCount > 0 ? "lime" : "secondary"}
                    onClick={(e) => {
                      handleConfirm(e)
                      handleOpenFilters(item.taxonomy)
                    }}
                    className={classNames({
                      button: true,
                      dim: checkCount === 0
                    })}>
                    {data.strings.confirm}
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    )
  }

  const Checkbox = ({ option }) => {
    const isChecked = !!store.selected.filter(
      (item) => item.slug === option.slug
    ).length

    return (
      <li
        onClick={() => {
          handleSelected(option)
        }}
        className={classNames([styles.checkbox, { "is-checked": isChecked }])}>
        <div className="square">
          {isChecked && (
            <CheckmarkRounded className="w-3" fillColor="#101010" />
          )}
        </div>
        <span className="label">{option.label}</span>
      </li>
    )
  }

  const Select = ({ item }) => {
    let filteredOptions = item.options

    if (item.parent) {
      filteredOptions = getOptionsFromParent(item)
    }

    let options = filteredOptions.map((item) => {
      return (
        <option key={item.slug} value={item.slug}>
          {item.label}
        </option>
      )
    })

    const disabled = item.method === "filter" && filteredOptions.length < 2

    let value

    if (item.method === "sort") {
      value = store.sortBy
      if (filterObj?.content !== undefined) {
        value = filterObj.sortBy
      }
    } else {
      value =
        store.selected
          .filter((select) => select.taxonomy === item.taxonomy)
          .shift()?.slug ?? ""
    }

    return (
      <div
        className={classNames([styles.selectWrapper, { disabled: disabled }])}>
        <label htmlFor="type-select">{item.label}</label>
        <select
          value={value}
          disabled={disabled}
          onChange={(e) => {
            doMultiSelect(e, item)
          }}
          className={styles.select}>
          {options}
        </select>
        <Caret className="caret" />
      </div>
    )
  }

  const MapButton = () => {
    return (
      <div
        className={classNames([
          styles.toggleWrapper,
          {
            "no-choices": store.selected.length === 0
          }
        ])}>
        <div onClick={handleShowMap} className="button">
          <Map className="w-6 mr-2.5 fill-current" />
          {data.strings.showMap}
        </div>
      </div>
    )
  }

  const Filters = () => {
    let filters = data.filters.map((item, key) => {
      switch (item.type) {
        case "checkboxes":
          return <Checkboxes key={key} item={item} />

        case "select":
          return <Select key={key} item={item} />
      }
    })

    let label = store.selected.length !== 0 ? data.strings.choices : ""
    let current = store.selected

    let terms = current.map((item, key) => {
      return (
        <li
          className="active-item"
          key={key}
          onClick={() => clearTerm(item.slug)}>
          <span>{item.label}</span>
          <Remove className="inline-block w-2 ml-2" />
        </li>
      )
    })

    return (
      <>
        <div
          className={classNames(styles.filters, {
            "four-filters": filters.length >= 4,
            "has-map": data.context === "calendar",
            "has-choices": store.selected.length > 0,
            "hide-choices": data?.hideChoice === true
          })}>
          {filters}
        </div>
        {data?.hideChoice !== true && (
          <div className={styles.activeChoices}>
            {store.selected.length > 0 && (
              <div className="choice-wrapper">
                <span className="label">{label}:</span>
                <ul>{terms}</ul>
                <span className="clear" onClick={() => clearAll()}>
                  {data.strings.clear}
                </span>
              </div>
            )}
            {!mapActive && !isMobile && <MapButton />}
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <form
        className={styles.form}
        action={data.url}
        onSubmit={(e) => handleConfirm(e)}>
        <div className={classNames([styles.search, "type-" + data.type])}>
          <div className="search-input">
            <Magnifier className="w-5 mr-4 fill-current stroke-current" />
            <input
              onChange={(e) => setInputValue(e.target.value)}
              ref={appContextStore.searchFormRef}
              value={inputValue}
              tabIndex="1"
              type="search"
              placeholder={data.placeholder}
            />
          </div>
          <div className="button-wrapper">
            <AdvButton />
            <Button
              theme="lime"
              className="search-button"
              onClick={(e) => handleConfirm(e)}>
              Sök
            </Button>
          </div>
        </div>
        {data.type === "multi" && advOpen && (
          <div className={styles.multi} ref={store.filterPanel}>
            <Filters />
          </div>
        )}
      </form>
      {advOpen && data.context === "calendar" && data.type === "multi" && (
        <div
          className={classNames(styles.map, {
            "no-choices": store.selected.length === 0,
            "map-active": mapActive
          })}>
          {!mapActive && isMobile && <MapButton />}
          {mapActive && children}
        </div>
      )}
    </>
  )
}
